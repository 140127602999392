import { useEffect, useState } from 'react';
import type { Identity, Monetization } from '@e24/mbbs';
import { getAccount } from '@innhold/core/account';

export function useAccountSdk() {
    const [accountSdk, setAccountSdk] = useState<[Identity, Monetization]>(null);

    useEffect(() => {
        const getAccountSdk = async () => {
            const account = await getAccount();
            setAccountSdk(account.getSdk());
        };
        getAccountSdk();
    }, []);

    return accountSdk ?? [];
}
