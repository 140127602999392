import { DefaultHeader } from '@e24/header';
import { useAccountSdk } from '@innhold/core/account/hooks';
import { getCmUrl } from '@innhold/core/cm';
import { $theme, Theme } from '@innhold/core/front/stores/theme';
import { usePulseSdk } from '@innhold/core/tracking/pulse/hooks';
import { useDarkMode } from '@innhold/lib/hooks/useDarkMode';
import '@e24/header/dist/styles.css';

export const Header = () => {
    const identitySdk = useAccountSdk();
    const pulseSdk = usePulseSdk();

    const cmUrl = getCmUrl('coreheader');
    const isDarkMode = useDarkMode();

    const toggleTheme = () => {
        $theme.set(isDarkMode ? Theme.Light : Theme.Dark);
    };

    return (
        <DefaultHeader
            cmp
            campaignManagerUrl={cmUrl}
            themeToggle={{
                darkMode: isDarkMode,
                onToggle: toggleTheme,
            }}
            getIdentitySdk={() => identitySdk}
            getPulseSdk={() => pulseSdk}
        />
    );
};
