import { useEffect, useState } from 'react';
import { useStore } from '@nanostores/react';
import { $theme, Theme } from '@innhold/core/front/stores/theme';
import { prefersDarkTheme } from '@innhold/core/theme';

export function useDarkMode() {
    const localTheme = useStore($theme);
    const [isDarkMode, setIsDarkMode] = useState(() => {
        if (localTheme) {
            return localTheme === Theme.Dark;
        }
        return prefersDarkTheme();
    });

    useEffect(() => {
        if (localTheme) {
            setIsDarkMode(localTheme === Theme.Dark);
        }
    }, [localTheme]);

    return isDarkMode;
}
